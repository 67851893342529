import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { OtoResponse } from '@interfaces/OtoResponse.interface';
import { TradeDocument } from '@interfaces/TradeDocument.interface';
import { encodeAgencyCode } from '@utils/EncodeAgencyCode.utils';
import { lastValueFrom, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private _documents: TradeDocument[];
  public get documents() {
    return this._documents;
  }
  public set documents(documents: TradeDocument[]) {
    this._documents = documents;
  }

  constructor(private _http: HttpClient) {}

  getAllDocuments(initiativeId: number) {
    const obs$ = this._http.get<OtoResponse<TradeDocument[]>>(`${environment.api}/trade-agreement/documents/initiative/${initiativeId}/all`, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }

  publishPage(typology: string, year: string, market:string, page: string) {
    const obs$ = this._http
      .post<OtoResponse<boolean>>(`${environment.api}/trade-agreement/documents/${typology}/${year}/${market}/${page}/publish`, {}, {
        withCredentials: true
      })
      .pipe(
        tap((resp) => {
          if (resp.code !== 200) throw new Error();
        }),
        map((resp) => resp.response),
      );

    return lastValueFrom(obs$);
  }

  publishDocument(typology: string, year: string, market:string,) {
    const obs$ = this._http.post<OtoResponse<boolean>>(`${environment.api}/trade-agreement/documents/${typology}/${year}/${market}/publish`, {}, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }

  getDocumentPreview(typology: string, year: string, market: string, agency_id: string) {
    const document$ = this._http.get(`${environment.api}/trade-agreement/documents/${typology}/${year}/${market}/${encodeAgencyCode(agency_id)}/preview/download`, {
      responseType: 'arraybuffer',
      withCredentials: true
    });

    return lastValueFrom(document$);
  }
}
