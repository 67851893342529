import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: 'generator', loadChildren: () => import('./modules/generator/generator.module').then((m) => m.GeneratorModule) },
  { path: 'upload', loadChildren: () => import('./modules/upload/upload.module').then((m) => m.UploadModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },
  { path: 'editing', loadChildren: () => import('./modules/editing/editing.module').then(m => m.EditingModule) },
  { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
