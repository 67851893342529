import { Component, OnInit } from '@angular/core';
import { DocumentService } from '@services/document.service';
import { MasterDataService } from '@services/master-data.service';

@Component({
  selector: 'cck-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'dashboard-costa-trade-agreement';
  loaded = false;

  constructor(private _documentService: DocumentService, private _masterDataService: MasterDataService) {}

  ngOnInit(): void {
    this._documentService.getAllDocuments(this._masterDataService.initiative.id).then(
      (resolved) => {
        this._documentService.documents = resolved;
        this.loaded = true;
      },
      (rejected) => {
        this.loaded = true;
      },
    );
  }
}
