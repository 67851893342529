import { ExcelColumn } from '@enums/ExcelColumn.enum';
import { MasterData as MasterDataInterface } from '@interfaces/MasterData.interface';
import { MasterDataStatus } from '@interfaces/MasterDataStatus.interface';

export class MasterData implements MasterDataInterface {
  id: number = null;
  agency_id: string = null;
  chain_id: string = null;
  chain_filiale: string = null;
  doc_type: string = null;
  agent_name: string = null;
  agt_address: string = null;
  zip_cd: string = null;
  city: string = null;
  provincia: string = null;
  phone_no: string = null;
  p_iva: string = null;
  agent_email: string = null;
  superconsortium_name: string = null;
  consortium_name: string = null;
  consortium_cod: string = null;
  cluster_network: string = null;
  segment_name_1: string = null;
  cluster: string = null;
  payment_code: string = null;
  new_payment_code: string = null;
  commissioni: string = null;
  pc_comm_promo_base: string = null;
  pc_comm_basic_base: string = null;
  pc_comm_std_base: string = null;
  pc_comm_all_inclusive: string = null;
  pc_comm_hsc: string = null;
  pc_comm_charter: string = null;
  pc_comm_linea: string = null;
  pc_comm_trasfert: string = null;
  pc_comm_hotel: string = null;
  pc_comm_train_cru: string = null;
  segui_c: string = null;
  segui_c_super_over: string = null;
  segui_c_super_light: string = null;
  wecare_jump: null = null;
  commissione_origine: null = null;
  jump_period_tgt_1: null = null;
  comm_std_j1_tgt_1: null = null;
  jump_period_tgt_2: null = null;
  comm_std_j1_tgt_2: null = null;
  jump_period_tgt_3: null = null;
  comm_std_j1_tgt_3: null = null;
  wecare_overcomm: string = null;
  target_1: string = null;
  target_2: null = null;
  target_3: null = null;
  over_1: null = null;
  over_2: string = null;
  over_3: string = null;
  target_ny: null = null;
  over_ny: string = null;
  importo_fijussione: null = null;
  logo: string = null;
  soglia: null = null;
  commission_status: null = null;
  modello_di_servizio_status: string = null;
  business_partner: string = null;
  area_manager: string = null;
  service_model_fy21: string = null;
  service_model_fy22: string = null;
  disabled: boolean = null;
  // created_at: Date = null;
  // updated_at: Date = null;
  statuses: MasterDataStatus[] = [];
  constructor(data: any[], id: number = null, disabled = false, statuses: MasterDataStatus[] = []) {
    this.id = id;
    this.agency_id = data[ExcelColumn.A];                      //A
    this.chain_id = data[ExcelColumn.B];                       //B
    this.chain_filiale = data[ExcelColumn.C]?.toUpperCase();   //C
    this.doc_type = data[ExcelColumn.D];                       //D
    this.agent_name = data[ExcelColumn.E];                     //E
    this.agt_address = data[ExcelColumn.F];                    //F
    this.zip_cd = data[ExcelColumn.G];                         //G
    this.city = data[ExcelColumn.H];                           //H
    this.provincia = data[ExcelColumn.I];                      //I
    this.phone_no = data[ExcelColumn.J];                       //J
    this.p_iva = data[ExcelColumn.K];                         //K
    this.agent_email = data[ExcelColumn.L];                   //L
    this.superconsortium_name = data[ExcelColumn.M];          //M
    this.consortium_name = data[ExcelColumn.N];               //N
    this.consortium_cod = data[ExcelColumn.O];                //O
    this.cluster_network = data[ExcelColumn.P];               //P
    this.segment_name_1 = data[ExcelColumn.Q];                //Q
    this.cluster = data[ExcelColumn.R];                       //R
    this.payment_code = data[ExcelColumn.S];                  //S
    this.new_payment_code = data[ExcelColumn.T];              //T
    this.commissioni = data[ExcelColumn.U];                   //U
    this.pc_comm_promo_base = data[ExcelColumn.V];            //V
    this.pc_comm_basic_base = data[ExcelColumn.W];            //W
    this.pc_comm_std_base = data[ExcelColumn.X];              //X
    this.pc_comm_all_inclusive = data[ExcelColumn.Y];         //Y
    this.pc_comm_hsc = data[ExcelColumn.Z];                   //Z
    this.pc_comm_charter = data[ExcelColumn.AA];               //AA
    this.pc_comm_linea = data[ExcelColumn.AB];                 //AB
    this.pc_comm_trasfert = data[ExcelColumn.AC];              //AC
    this.pc_comm_hotel = data[ExcelColumn.AD];                 //AD
    this.pc_comm_train_cru = data[ExcelColumn.AE];             //AE
    this.segui_c = data[ExcelColumn.AF];                       //AF
    this.segui_c_super_over = data[ExcelColumn.AG];            //AG
    this.segui_c_super_light = data[ExcelColumn.AH];           //AH
    this.commissione_origine = data[ExcelColumn.AI];           //AI
    this.jump_period_tgt_1 = data[ExcelColumn.AJ];             //AJ
    this.comm_std_j1_tgt_1 = data[ExcelColumn.AK];             //AK
    this.jump_period_tgt_2 = data[ExcelColumn.AL];             //AL
    this.comm_std_j1_tgt_2 = data[ExcelColumn.AM];             //AM
    this.jump_period_tgt_3 = data[ExcelColumn.AN];             //AN
    this.comm_std_j1_tgt_3 = data[ExcelColumn.AO];             //AO
    this.wecare_overcomm = data[ExcelColumn.AP];               //AP
    this.target_1 = data[ExcelColumn.AQ];                      //AQ
    this.target_2 = data[ExcelColumn.AR];                      //AR
    this.target_3 = data[ExcelColumn.AS];                      //AS
    this.over_1 = data[ExcelColumn.AT];                        //AT
    this.over_2 = data[ExcelColumn.AU];                        //AU
    this.over_3 = data[ExcelColumn.AV];                        //AV
    this.target_ny = data[ExcelColumn.AW];                     //AW
    this.over_ny = data[ExcelColumn.AX];                       //AX
    this.importo_fijussione = data[ExcelColumn.AY];            //AY
    this.logo = data[ExcelColumn.AZ];                          //AZ
    this.soglia = data[ExcelColumn.BA];                        //BA
    this.commission_status = data[ExcelColumn.BB];             //BB
    this.modello_di_servizio_status = data[ExcelColumn.BC];    //BC
    this.business_partner = data[ExcelColumn.BD];              //BD
    this.area_manager = data[ExcelColumn.BE];                  //BE
    this.service_model_fy21 = data[ExcelColumn.BF];            //BF
    this.service_model_fy22 = data[ExcelColumn.BG];            //BG
    this.disabled = disabled;          // ! default a false
    // this.created_at =                 null; // !GESTITI A BE
    // this.updated_at =                 null; // !GESTITI A BE
    this.statuses = statuses; // !PER ORA INUTILIZZATI (SEE SVILUPPO SERVICE MODEL GENNAIO 2023)
  }

  public static fromResponse(data: MasterDataInterface) {
    return new MasterData([
      data.agency_id,
      data.chain_id,
      data.chain_filiale?.toUpperCase(),
      data.doc_type,
      data.agent_name,
      data.agt_address,
      data.zip_cd,
      data.city,
      data.provincia,
      data.phone_no,
      data.p_iva,
      data.agent_email,
      data.superconsortium_name,
      data.consortium_name,
      data.consortium_cod,
      data.cluster_network,
      data.segment_name_1,
      data.cluster,
      data.payment_code,
      data.new_payment_code,
      data.commissioni,
      data.pc_comm_promo_base,
      data.pc_comm_basic_base,
      data.pc_comm_std_base,
      data.pc_comm_all_inclusive,
      data.pc_comm_hsc,
      data.pc_comm_charter,
      data.pc_comm_linea,
      data.pc_comm_trasfert,
      data.pc_comm_hotel,
      data.pc_comm_train_cru,
      data.segui_c,
      data.segui_c_super_over,
      data.segui_c_super_light,
      data.commissione_origine,
      data.jump_period_tgt_1,
      data.comm_std_j1_tgt_1,
      data.jump_period_tgt_2,
      data.comm_std_j1_tgt_2,
      data.jump_period_tgt_3,
      data.comm_std_j1_tgt_3,
      data.wecare_overcomm,
      data.target_1,
      data.target_2,
      data.target_3,
      data.over_1,
      data.over_2,
      data.over_3,
      data.target_ny,
      data.over_ny,
      data.importo_fijussione,
      data.logo,
      data.soglia,
      data.commission_status,
      data.modello_di_servizio_status,
      data.business_partner,
      data.area_manager,
      data.service_model_fy21,
      data.service_model_fy22,
    ], data.id, data.disabled, data.statuses);
  }
}
