import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MasterData } from '@classes/MasterData.class';
import { environment } from '@environments/environment';
import { OtoResponse } from '@interfaces/OtoResponse.interface';
import { encodeAgencyCode } from '@utils/EncodeAgencyCode.utils';
import { lastValueFrom, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  public get initiative() {
    return {
      id: 2,
      fiscal_year: '2024',
      market: 'I',
    }; // * TODO AGGIUNGERE PAGINA SELEZIONE INIZIATIVA IN FUTURO
  }

  constructor(private _http: HttpClient) {}

  public createMasterData(data: MasterData[]) {
    const obs$ = this._http.post<OtoResponse<boolean>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/create`, data, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }

  public getAllMasterData() {
    const obs$ = this._http.get<OtoResponse<MasterData[]>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/get`, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }

  public getAllMasterDataSlim() {
    const obs$ = this._http.get<OtoResponse<MasterData[]>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/get/slim`, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
      map((resp) => resp.map(MasterData.fromResponse)),
    );

    return lastValueFrom(obs$);
  }

  public getAllMasterDataPaginated(page: number, items: number, search: string) {
    const params = new HttpParams({
      fromObject: {
        page,
        items,
        search
      }
    })
    return this._http.get<OtoResponse<{ count: number, items: MasterData[]}>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/paginated`, {
      params,
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
      map((resp) => ({
        count: resp.count,
        items: resp.items.map(MasterData.fromResponse),
      })),
    );
  }

  public getSingleMasterData(agencyCode: string) {
    const obs$ = this._http.get<OtoResponse<MasterData>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/get/${encodeAgencyCode(agencyCode)}`, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => MasterData.fromResponse(resp.response)),
    );

    return lastValueFrom(obs$);
  }

  public updateMasterData(data: MasterData[]) {
    const obs$ = this._http.put<OtoResponse<boolean>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/update`, data, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }

  public deleteMasterData(agencyCode: string) {
    const obs$ = this._http.delete<OtoResponse<boolean>>(`${environment.api}/trade-agreement/masterdata/initiative/${this.initiative.id}/delete/${encodeAgencyCode(agencyCode)}`, {
      withCredentials: true
    }).pipe(
      tap((resp) => {
        if (resp.code !== 200) throw new Error();
      }),
      map((resp) => resp.response),
    );

    return lastValueFrom(obs$);
  }
}
